import React from 'react';

import { Container } from './styles';

const Terms = () => {
    return (
        <Container>
            <h2><b>PARA VOCÊ ENTENDER MELHOR COMO FUNCIONA O STRATEGI APP</b></h2>
            <p> Fizemos o máximo para reduzir e simplificar as regras do <b>STRATEGI APP.</b> Por isso, separamos abaixo os pontos mais importantes para você, que também podem ser lidos de forma bem completa e detalhada nos <b> Termos de Uso a seguir.</b> </p>

            <p> Além disso, estamos sempre disponíveis para tirar qualquer dúvida que você tenha pelo e-mail contato@strategi.in e telefone 84 99116-1258. </p>
            
            <p> <b>1) </b>Nosso negócio é um sistema que possibilita a integração entre Usuários Corretores e Usuárias Incorporadoras/Imobiliárias para gestão de comissionamentos. </p>

            <p> <b>2) </b>  Além disso, o <b>STRATEGI APP</b> possui diversas funcionalidades, como a possibilidade de antecipação de carteira para o Usuário Corretor, emissão de relatórios e fluxo de pagamento transparente para todos os envolvidos. </p>

            <p> <b>3) </b>  Para divulgar nossos serviços e agendar demonstrações do <b>STRATEGI APP</b> temos um site: <a href="https://strategi.app/">https://strategi.app/</a>. No site poderemos disponibilizar materiais, como informações sobre o sistema, que devem ser utilizados por você apenas para visualização pessoal e não comercial. </p>

            <p> <b>4) </b>  Nós <b>não</b> somos uma corretora, incorporadora ou imobiliária. Somos apenas uma plataforma de tecnologia que tem como objetivo permitir que todo o processo de pagamento de comissões seja facilitado e monitorado. </p>

            <p> <b>5) </b>  Os Usuários devem pagar valores para utilizar os serviços do <b>STRATEGI APP</b>. Esses valores estarão sempre expostos antes de você fazer a contratação. </p>

            <p> <b>6) </b>  Nos esforçamos bastante para que nossas plataformas sejam seguras, mas ainda assim recomendamos que, antes de baixar qualquer conteúdo, você instale antivírus e programas de proteção. </p>

            <p> <b>7) </b>  Nossos Termos de Uso poderão mudar, mas você sempre poderá acessar a versão mais atualizada nas nossas plataformas. Além disso, se formos realizar alguma ação que a lei exija sua autorização, você receberá um aviso antes para poder aceitar ou recusar. </p>
            
            <p> <b>8) </b>Temos uma Política de Privacidade que trata sobre o que fazemos com os seus dados pessoais. É muito importante que você leia e entenda esse documento também! </p>
            
            <p> <b>9) </b>Os Termos de Uso a seguir estão divididos da seguinte forma para facilitar o seu acesso à informação: </p>

            <ol type="a">
                <li>Data de Disponibilização do Texto;</li>
                <li>Explicação dos Termos Técnicos ou em Língua Estrangeira;</li>
                <li>Serviços;</li>
                <li>Cadastro dos Usuários;</li>
                <li>Pagamento pelo Uso da Plataforma;</li>
                <li>Responsabilidades das Partes;</li>
                <li>Isenção de Responsabilidade do STRATEGI APP;</li>
                <li>Regras de Conduta e Proibições;</li>
                <li>Propriedade Intelectual;</li>
                <li>Tratamento de Dados Pessoais, Privacidade e Segurança;</li>
                <li>Desdobramentos do acesso à Plataforma;</li>
                <li>Alterações dos Termos e Condições de Uso;</li>
                <li>Definição da Lei Aplicável e do Foro de eleição;</li>
                <li>Canal de Contato;</li>
            </ol>

            <h4 style={{'text-align': 'right'}}><b>STRATEGI.</b> </h4>

            <h1 style={{'text-align': 'center'}}>TERMOS DE USO</h1>
            
            <p>Este sistema, cujo nome é <b>STRATEGI APP</b> e o site <a href="https://strategi.app/">https://strategi.app/</a> são de propriedade, mantidos e controlados por STRATEGI CONSULTORIA LTDA. (<b>“STRATEGI”</b>), inscrita no CNPJ sob o n. 05.728.249/0001-31, com sede na Av. Nascimento de Castro, n° 1881, Dix-Sept Rosado, CEP 59056-450, Natal, Rio Grande do Norte.</p>
            
            <p>Este documento visa prestar informações sobre o modo de utilização das plataformas pelos Usuários e suas ferramentas, condições, nomenclaturas, direitos e deveres, além de preservar a privacidade dos Usuários. Alertamos que todo o texto deve ser lido com atenção e, caso você não concorde com o conteúdo de nossos termos e/ou política de privacidade, não dê prosseguimento a navegação ou a utilização de nossos serviços. Recomendamos, ainda, que caso seja aceito, que você armazene ou imprima uma cópia deste contrato, incluindo todas as políticas.</p>

            <h4>1. DATA DE DISPONIBILIZAÇÃO DO TEXTO</h4>

            <p>O presente documento foi redigido e disponibilizado em 02/06/2020.</p>

            <h4>2. EXPLICAÇÃO DOS TERMOS TÉCNICOS OU EM LÍNGUA ESTRANGEIRA</h4>

            <p>Abaixo estão dispostos os significados das nomenclaturas técnicas e termos na língua inglesa. <br/>
                Login: É o processo que permite o acesso a um sistema informático, controlado por meio de identificação e autenticação do Usuário pelas credenciais fornecidas por esse mesmo internauta. <br />
                Online: Termo da língua inglesa cujo significado literal é “em linha”. É habitualmente usado para designar que um determinado Usuário da internet ou de outra rede de computadores está conectado à rede. <br />
                Plataforma: Conjunto de soluções da <b>STRATEGI</b>, que consistem em site, aplicativo, sistema e páginas de divulgação da empresa.<br />
                Usuário: Menção em conjunto dos Usuários Corretores e das Usuárias Incorporadoras/Imobiliárias.<br />
                Usuário Corretor: Profissional que se cadastra na plataforma e é encarregado de intermediar as negociações de compra, venda, locação e permuta de imóveis.<br />
                Usuária Incorporadora/Imobiliária: Empresa que se cadastra na plataforma, podendo ser responsável pelas bases e projetos de um empreendimento imobiliário ou ser uma intermediária na transação de compra, venda, locação e permuta de imóveis.<br />
                Vírus: Software malicioso que infecta sistemas e computadores, com objetivo de causar algum dano, faz cópias de si mesmo e tenta se espalhar para outros computadores, utilizando-se de diversos meios.<br />
            </p>

            <h4>3. SERVIÇOS</h4>

            <p>3.1. <b>O que somos</b>: O <b>STRATEGI APP</b> consiste, mas não se limita, a um sistema que possibilita a integração entre Usuários Corretores e Usuárias Incorporadoras/Imobiliárias para gestão de comissionamentos.</p>

            <p>3.2. Por meio do sistema, o Usuário Corretor terá diversas funcionalidades, como: visualizar a sua carteira, verificar a previsão dos seus recebíveis, gerenciar o pagamento das suas comissões e antecipar sua carteira. <br/></p>
            <p>&emsp; 3.2.1. Na funcionalidade de antecipação de carteira o Usuário Corretor poderá receber o valor em até 48 (quarenta e oito) horas, pagando uma taxa ao <b>STRATEGI APP</b>, cujo valor estará sempre disponível antes da contratação do serviço</p>

            <p>3.3. Para a Usuária Incorporadora/Imobiliária o <b>STRATEGI APP</b> possui funcionalidades que permitem: a gestão de comissionamentos aos corretores, emissão de relatórios e um fluxo de pagamento transparente para todos os envolvidos.</p>

            <p>3.4. A integração entre o sistema do Usuário Corretor e Usuária Incorporadora/Imobiliária permite que todo o processo de pagamento seja monitorado e notificado às partes.</p>

            <p>3.5. O site <a href="https://strategi.app/">https://strategi.app/</a> é utilizado para divulgar serviços e agendar demonstrações do <b>STRATEGI APP</b>.</p>
            <p>&emsp; 3.5.1. No site poderão ser disponibilizados materiais, como informações sobre o sistema, que devem ser utilizados pelos Usuários apenas para visualização pessoal e não comercial.</p>

            <p>3.6. O <b>STRATEGI APP</b> não oferece serviços de corretora, incorporadora ou imobiliária.</p>

            <p>3.7. Ao utilizar as plataformas, o Usuário deve compreender e aceitar que todas as suas ações e escolhas são livres e não possuem nenhum tipo de influência ou ingerência da <b>STRATEGI</b>.</p>

            <p>3.8. Fica esclarecido que o presente serviço não estabelece entre a <b>STRATEGI</b> e as partes qualquer vínculo empregatício, societário ou associativo, permanecendo cada parte como única responsável por todas as suas respectivas despesas e encargos, sejam de natureza trabalhista, previdenciária, fiscal, securitária, civil, penal ou de qualquer outra natureza ou espécie.</p>

            <h4>4. CADASTRO DOS USUÁRIOS</h4>

            <p>4.1. Para o cadastro e utilização dos serviços do <b>STRATEGI APP</b> serão requeridos os seguintes dados:</p>
            
            <p>&emsp;4.1.1. Do Usuário Corretor: Razão Social, CNPJ, nome completo do representante legal e CPF do representante legal (caso seja uma pessoa jurídica) ou nome completo e CPF (caso seja uma pessoa física), endereço completo, e-mail e dados bancários.</p>
            <p>&emsp;4.1.2. Da Usuária Incorporadora/Imobiliária: Razão Social, CNPJ, endereço completo, e-mail, dados bancários, nome completo do representante legal e CPF do representante legal.</p>
            <p>&emsp;&emsp;4.1.2.1. Solicitaremos também os seguintes dados dos colaboradores da Usuária Incorporadora/Imobiliária que utilizam o sistema: Nome completo, CPF e e-mail.</p>

            <p>Cada Usuário determinará o seu login e senha de acesso, sendo de sua exclusiva responsabilidade a manutenção do sigilo dessas informações. O <b>STRATEGI APP</b> não se responsabiliza pelas ações e danos que poderão ser causados pelo acesso irregular da conta de acesso por terceiros.</p>

            <p>&emsp;4.2.2. Ainda, os Usuários possuem total responsabilidade caso forneçam o acesso de sua conta à colaboradores, prestadores de serviços ou terceiros, sendo que o <b>STRATEGI APP</b> não responderá e não terá o dever de desfazer qualquer transação financeira ou contratação realizada por essas pessoas durante a utilização do aplicativo.</p>

            <p>4.3. Os Usuários são responsáveis por fornecer informações verdadeiras, precisas, completas e atualizadas.</p>

            <p>4.4. O <b>STRATEGI APP</b> poderá recusar, suspender ou cancelar sem notificação prévia a conta de acesso de um Usuário sempre que suspeitar que as informações fornecidas são falsas, incompletas, desatualizadas ou imprecisas ou, ainda, nos casos indicados nas leis e regulamentos aplicáveis, nestes Termos de Uso ou em qualquer Política.</p>

            <h4>5. PAGAMENTO PELO USO DA PLATAFORMA</h4>

            <p>5.1. O Usuário deverá pagar valores para utilizar os serviços do <b>STRATEGI APP</b>. Essas quantias serão variáveis e estarão sempre amplamente divulgadas nos anúncios antes da possibilidade de adesão ao serviço. </p>

            <p>&emsp;5.1.1. Respeitamos todos os seus direitos, inclusive o direito de arrependimento e cancelamento do serviço que seja contratado de forma online, com reembolso total dos valores pagos, no prazo de até 07 (sete) dias, contados a partir da data de contratação do sistema.</p>
            <p>&emsp;5.1.2. Após o prazo de 07 (sete) dias, caso o Usuário queira cancelar os serviços do <b>STRATEGI APP</b>, não haverá devolução de valores.</p>

            <h4>6. RESPONSABILIDADE DAS PARTES</h4>

            <p><b>6.1. Responsabilidade da STRATEGI:</b></p>
            <p>&emsp;6.1.1. Realizar os serviços conforme o descrito nestes termos de uso;</p>
            <p>&emsp;6.1.2. Responsabilizar-se pelo funcionamento do sistema e pelas correções que eventualmente sejam necessárias;</p>
            <p>&emsp;6.1.3. Informar qualquer alteração dos serviços aos Usuários, por meio de comunicados simples na plataforma;</p>
            
            <p><b>6.2. Responsabilidade dos Usuários:</b></p>

            <p>&emsp;6.2.1. Utilizar as plataformas conforme os critérios de utilização definidos pela <b>STRATEGI</b>, sem alterar a sua programação, quebrar senhas ou realizar procedimentos que venham causar prejuízos a <b>STRATEGI</b> e aos demais Usuários;</p>
            <p>&emsp;6.2.2. Responsabilizar-se para todos os efeitos, inclusive jurídicos, pelo teor das informações que introduzir e pelos compromissos que assumir nas plataformas;</p>
            <p>&emsp;6.2.3. Respeitar integralmente estes Termos de Uso, Políticas de Privacidade, legislação vigente e eventuais contratos entre as partes.</p>
            
            <h4>7. ISENÇÃO DE RESPONSABILIDADE DA STRATEGI</h4>
            <p>7.1. A <b>STRATEGI não se responsabiliza por:</b></p>
            <p>&emsp;7.1.1. Eventual indisponibilidade das plataformas, a qual não tenha dado causa.</p>
            <p>&emsp;7.1.2. Condutas dos Usuários e pelo descumprimento de suas obrigações.</p>
            <p>&emsp;7.1.3. Inserção de informações falsas pelos Usuários;</p>
            <p>&emsp;7.1.4. Danos que o Usuário possa experimentar por ações exclusivas de terceiros, bem como falhas na conexão de rede e interações maliciosas como vírus</p>
            <p>&emsp;7.1.5. Danos que o Usuário possa ter em decorrência do mau uso das plataformas em desconformidade com estes Termos, com a Política de Privacidade, com a lei ou ordens judiciais.</p>
            <p>&emsp;7.1.6. Casos fortuitos ou de força maior.</p>
            <p>&emsp;7.1.7. Prestações de serviços dos Usuários Corretores;</p>
            <p>&emsp;7.1.8. Realizações de pagamentos pelas Usuárias Incorporadoras/Imobiliárias;</p>
            <p>&emsp;7.1.9. 7.1.9. Transações bancárias realizadas por Instituições Financeiras.</p>


            <h4>8. REGRAS DE CONDUTA E PROIBIÇÕES</h4>

            <p>8.1. Os Usuários <b>não</b> podem:</p>

            <p>&emsp;8.1.1. Lesar direitos da <b>STRATEGI</b>, de suas plataformas, dos operadores das plataformas, de outros Usuários, de terceiros ou agir sob qualquer meio ou forma que possa contribuir com tal violação;</p>
            <p>&emsp;8.1.2. Executar atos que limitem ou impeçam a utilização das plataformas ou acessar ilicitamente o <b>STRATEGI APP</b>;</p>
            <p>&emsp;8.1.3. Utilizar as ferramentas para praticar ações ilícitas, como furtos, roubos, golpes e apropriações indébitas;</p>
            <p>&emsp;8.1.4. Difundir mensagens não relacionadas com as plataformas ou com suas finalidades, incluindo mensagens com conteúdo impróprio;</p>
            <p>&emsp;8.1.5. Inserir dados que sejam falsos, desatualizados ou incompletos;</p>
            <p>&emsp;8.1.6. Responsabilizar a <b>STRATEGI</b> por condutas de Usuários que estejam cadastrados nas plataformas ou terceiros.</p>
            
            <p>8.2. Ao sinal de qualquer descumprimento de conduta, o Usuário poderá ser bloqueado ou excluído do <b>STRATEGI APP</b>, sem necessidade de aviso prévio.</p>

            <h4><b>9. PROPRIEDADE INTELECTUAL</b></h4>

            <p>9.1. A titularidade e os direitos relativos às plataformas pertencem exclusivamente à <b>STRATEGI</b>. O acesso ao aplicativo, ao sistema, ao site e a sua regular utilização pelo Usuário não lhe conferem qualquer direito ou prerrogativa sobre propriedade intelectual ou outro conteúdo neles inseridos.</p>

            <p>9.2. Todo o conteúdo das plataformas, incluindo nome, marca, domínio, programas, bases de dados, arquivos, textos, desenhos, fotos, layouts, cabeçalhos, materiais, informativos e demais elementos, foi criado, desenvolvido ou cedido à <b>STRATEGI</b>, sendo, portanto, de propriedade exclusiva da <b>STRATEGI</b> ou a ela licenciado e encontra-se protegido pelas leis brasileiras e tratados internacionais que versam sobre direitos de propriedade intelectual.</p>
            <p>&emsp;9.2.1. Os materiais exibidos no site do <b>STRATEGI APP</b> podem incluir erros técnicos, tipográficos ou fotográficos. A <b>STRATEGI</b> não garante que qualquer material em seu site seja preciso, completo ou atual. A <b>STRATEGI</b> pode fazer alterações nos materiais contidos em suas plataformas a qualquer momento, sem aviso prévio. No entanto, a <b>STRATEGI</b> não se compromete a atualizar os materiais.</p>

            <p>9.3. São proibidas: a exploração, cessão, imitação, cópia, plágio, aplicação de engenharia reversa, tentativa de invasão (hackear), armazenamento, alteração, modificação de características, ampliação, venda, locação, doação, alienação, transferência, reprodução, integral ou parcial, de qualquer conteúdo do <b>STRATEGI APP</b> e das plataformas a ele relacionadas.</p>

            <p>9.4. A pessoa que violar as proibições contidas na legislação sobre propriedade intelectual e nestes Termos será responsabilizada, civil e criminalmente, pelas infrações cometidas, além de poder ser penalizado nas plataformas.</p>

            <h4><b>10. TRATAMENTO DE DADOS PESSOAIS, PRIVACIDADE E SEGURANÇA</b></h4>

            <p>10.1. O <b>STRATEGI APP</b> e o site dispõem de uma política específica para regular a coleta, guarda e tratamento de dados pessoais, bem como a sua segurança: Política de Privacidade. Essa política específica integra inseparavelmente estes Termos, ressaltando-se que os dados de utilização das plataformas serão arquivados nos termos da legislação em vigor.</p>

            <h4><b>11. DESDOBRAMENTOS DO ACESSO ÀS PLATAFORMAS</b></h4>

            <p>11.1. Apesar dos melhores esforços da <b>STRATEGI</b> para fornecer as melhores tecnologias para manter a conexão e sincronização online e acesso seguro aos Usuários, em virtude de dificuldades técnicas, aplicações de internet ou problemas de transmissão, é possível ocorrer cópias inexatas ou incompletas das informações contidas nas plataformas. Além disso, vírus de computador ou outros programas danosos também poderão ser baixados inadvertidamente das plataformas.</p>

            <p>&emsp;11.1.1. A <b>STRATEGI</b> recomenda a instalação de antivírus ou protetores adequados.</p>

            <p>11.2. A <b>STRATEGI</b> não possui responsabilidade sobre os sites e seus respectivos conteúdos que sejam eventualmente vinculados às suas plataformas. A inclusão de qualquer link não implica endosso pela <b>STRATEGI</b>. O uso de qualquer site vinculado é de responsabilidade exclusiva do Usuário.</p>

            <p>11.3. A <b>STRATEGI</b> se reserva o direito de unilateralmente modificar o <b>STRATEGI APP</b>, as plataformas, bem como a configuração, a apresentação, o desenho, o conteúdo, as funcionalidades, as ferramentas ou qualquer outro elemento, inclusive o seu cancelamento.</p>

            <h4><b>12. ALTERAÇÕES DOS TERMOS E CONDIÇÕES DE USO</b></h4>

            <p>12.1. A <b>STRATEGI</b> poderá unilateralmente adicionar e/ou modificar qualquer cláusula contida nestes Termos de Uso. A versão atualizada valerá para o uso das plataformas realizado a partir de sua publicação. A continuidade de acesso ou utilização das plataformas, depois da divulgação, confirmará a vigência dos novos Termos de Uso pelos Usuários.</p>

            <p>12.2. Caso a mudança efetuada necessite de consentimento do Usuário, será apresentada a opção de aceitar de forma livre, inequívoca e informada o novo texto ou de recusá-lo.</p>

            <p>12.3. Caso o Usuário não esteja de acordo com a alteração, poderá não fornecer consentimento para atos específicos ou poderá rescindir totalmente seu vínculo com a <b>STRATEGI</b>. Essa rescisão não eximirá, no entanto, o Usuário de cumprir com todas as obrigações assumidas sob as versões precedentes dos Termos de Uso.  </p>

            <h4><b>13. LEI APLICÁVEL E FORO DE ELEIÇÃO</b></h4>

            <p>13.1. As plataformas são controladas, operadas e administradas na cidade de Natal, Estado do Rio Grande do Norte, Brasil, podendo ser acessadas por qualquer dispositivo conectado à Internet, independentemente de sua localização geográfica.</p>

            <p>&emsp;13.1.1. A <b>STRATEGI</b> não garante que as plataformas sejam apropriadas ou estejam disponíveis para uso em outros locais. As pessoas que acessam ou usam o <b>STRATEGI APP</b> ou o site a partir de outras jurisdições o fazem por conta própria e são responsáveis pelo cumprimento das leis regionais/nacionais.</p>

            <p>13.2. O Usuário concorda que a legislação aplicável para fins destes Termos e Condições de Uso e das Políticas de Privacidade será a vigente na República Federativa do Brasil.</p>

            <p>13.3. A <b>STRATEGI</b> e o Usuário concordam que o Foro de Natal, Estado do Rio Grande do Norte, Brasil, será o único competente para dirimir qualquer questão ou controvérsia oriunda ou resultante do uso das plataformas, renunciando expressamente a qualquer outro, por mais privilegiado que seja, ou venha a ser.</p>

            <h4><b>14. CONTATO</b></h4>

            <p>14.1. A <b>STRATEGI</b> disponibiliza os seguintes canais para receber todas as comunicações que os Usuários desejarem fazer: e-mail contato@strategi.in e telefone 84 99116-1258.</p>
        </Container>
    )
}

export default Terms